
import {defineComponent,ref,reactive} from "vue"
import { useRoute,useRouter } from 'vue-router'
import {useStore} from "vuex"
import Api from '@/http/modules/index'
import ScanBarcode from "@/components/ScanBarcode.vue"
import ApprovalTable from "@/components/ApprovalTable.vue"
import Invoice from "@/views/ViewsComponents/paymentPool/Invoice.vue"
import { ElMessage, ElMessageBox} from 'element-plus'
import dayjs from 'dayjs'
import calculation from '@/utils/calculation'

export default defineComponent({
    name:'ApprovalHistoryDetail',
    components:{
        ScanBarcode,
        ApprovalTable,
        Invoice
    },
    setup(){
        let {Sub}=calculation
        let store = useStore()
        let route = useRoute()
        let router = useRouter()
        const {
            //审批记录详情列表
            'POST/approved/query_details': approvedQuerySetailsApi,
            //需要二次审批的数据（用款申请的数据）
            'POST/approved/sec_approved': secondaryApprovalDataApi,
            //修改进项税额纳税差异
            'PATCH/approved/update_amount': approvedUpdateAmountApi,
            //二次审批
            'POST/approved/approved_record': secondaryApprovalApi,
            //查询支付单据的票据
            'GET/invoice': invoiceListApi,
            // 保存票据
            'POST/invoice/save': saveInvoiceApi,
            // 删除票据
            'DELETE/invoice': deleteInvoiceApi,
            // 保存添加的共享票据
            'POST/invoice/save_share': saveSpInvoiceApi,
            // 获取共享票据
            'POST/invoice/share_conditions': invoiceShareConditionsApi,
            // 回退
            'POST/approved/roll_back_status': approvedRollBackApi,
            // 删除共享票据
            'DELETE/invoice/share_delete': delInvoiceApi,
            // 解锁/锁定
            'PATCH/pay_pool/locked': lockedApi,
        } = Api
        
        const historyDetailData=ref([])
        const tableLoading=ref(false)
        const pageSetting=reactive({
            page: 1,
            size: 30,
            totalNum: 0
        })
        const taxDialogVisible=ref(false)
        const approvalDialogVisiable=ref(false)
        const approvalDataLoading=ref(false)
        const approvalData = ref<any>([])
        const expenseType=ref("COST_APPLY")
        const invoiceData=ref<any>([])
        const SP_IncoiceData=ref<any>([])
        const SpInvpiceVisible=ref(false)
        const currentCoEstimateAmount=ref()
        const backDialogVisiable=ref(false)
        const backReason=reactive({
            reimburseId:"",
            payApproveStatus:"",
            reason:""
        })

      
        const getTableData=()=>{
            tableLoading.value=true
            let {page,size}=pageSetting
            let formdata=new FormData()
            formdata.append('page',page.toString())
            formdata.append('size',size.toString())
            formdata.append('approvedRecordId',route.params.id.toString())
            approvedQuerySetailsApi(formdata as any).then((res:any)=>{
                historyDetailData.value=res.data
                pageSetting.totalNum=res.rowCount
                tableLoading.value=false
            })
        }
        const currentCoNo=ref() //当前操作的数据的co单号
        const currentCoId=ref() //当前co单的id
        getTableData()
        const pageChange=(page:number)=>{
            pageSetting.page=page
            getTableData()
        }
        interface Itax{
            reimburseIds:any,
            inComeTax:number|undefined,
            taxDifference:number|undefined
        }
        const taxFormInline:Itax = reactive({
            inComeTax: undefined,
            taxDifference: undefined,
            reimburseIds:""
        })
        interface Option {
            desc: string
            disabled: boolean
            id:number|undefined
        } 
        const approvalList = ref<Option[]>([])
        // 编辑
        const invoiceLoading = ref<boolean>(false)
        const editTax=(data:any)=>{
            // 打开编辑发票弹框
            taxDialogVisible.value = true
            let { id, no, estimateAmount } = data

            currentCoEstimateAmount.value = estimateAmount
            currentCoNo.value = no
            currentCoId.value = id
            invoiceData.value = []
            // 获取发票
            invoiceLoading.value = true
            invoiceListApi({billId:id}).then((rep:any)=>{
                invoiceData.value = rep.map((_invoiceRow: any)=>{
                    // 设置no编号
                    _invoiceRow.no = no
                    // 设置已经存在发票,可以编辑
                    _invoiceRow.isEdit = true
                    return _invoiceRow
                })
            }).finally(()=>{
                invoiceLoading.value=false
            })

        }
         const changeTax=()=>{
            approvedUpdateAmountApi(taxFormInline).then((res:any)=>{
                taxDialogVisible.value=false
                // 重置弹窗值
                taxFormInline.inComeTax=undefined
                taxFormInline.taxDifference=undefined
                taxFormInline.reimburseIds=""
                // 刷新列表
                getTableData()
            })
        }
        
        const barCode=(code:string)=>{
            let target:Option|undefined=approvalList.value.find((el:any)=>el.no==code)
                if(!target){
                        ElMessage.error("列表中不包含该数据，请核对数据")
                    return
                }
                    approvalData.value.push(target.id)
        }
         const saveApproval=async ()=>{
            if(!approvalData.value.length){
                approvalDialogVisiable.value=false
                return
            }
            // console.log(approvalData.value)
            let params={
                "billIds":approvalData.value,
                "userId":store.state.userId,
                "approvedRecordId":route.params.id
            }
            await secondaryApprovalApi(params)
            ElMessage.success("保存成功")
            approvalDialogVisiable.value=false
            getTableData()
        }
         const approvalProcess=()=>{
            // if(approvalDialogVisiable.value)return
            approvalList.value=[]
            approvalDialogVisiable.value=true
            approvalDataLoading.value=true
            approvalData.value=[]
            let params={
                approvedRecordId:route.params.id,
                reimburseType:expenseType.value
            }
            secondaryApprovalDataApi(params as any).then((res:any)=>{
                    res.forEach((el:any)=>{el.desc=el.no+' '+el.creator})
                    approvalList.value=res
                    approvalDataLoading.value=false
            }).catch(()=>{
                    approvalDataLoading.value=false
            })  
        }
        const filterMethod=(query:string,item:any)=>{
            if(query){
                return item.no.toString().search(query)!=-1
            }
            return item
        }
        const invoiceValues = {
            no:currentCoNo.value, //单号
            invoiceType:"",
            invoiceCode:"",
            invoiceNo:"",
            invoiceDate:"",
            totalAmount:0,
            taxRate:0,
            taxAmount:0,
            taxExcluded:0,
            inputTaxAmount:0,
            isEdit:false,
            id:"",
            note:'',
            isManual:0
        }
        const addInvoice=(amount?:number)=>{
            let rowValue=JSON.parse(JSON.stringify(invoiceValues))
            rowValue.no=currentCoNo.value
            if(amount&&amount>0){
                rowValue.invoiceType="NO"
                rowValue.totalAmount=amount
            }
            invoiceData.value.push(rowValue)
        }
        const addSpInvoice=()=>{
            SP_IncoiceData.value.unshift({
                invoiceType:"",
                invoiceCode:"",
                invoiceNo:"",
                invoiceDate:"",
                totalAmount:0,
                isEdit:false,
                id:"",
                note:''
            })
        }
        
        const saveInvoice=async (data:any)=>{
            let {invoiceType,invoiceCode,invoiceNo,invoiceDate,totalAmount,taxRate,taxAmount,taxExcluded,inputTaxAmount,id,note,isManual}=data
            if(!invoiceType){ //判断票据类型是否填写
                ElMessage.warning("请完善票据信息")
                return
            }
            if(invoiceType=='OR'||invoiceType=="SH_OR"||invoiceType=="SP"||invoiceType=="SH_SP"){//类型为 专票，普票。共用专票/普票 限制
                if(!invoiceNo||!invoiceDate||!totalAmount||totalAmount==0){ //发票 号码，代码，日期，总金额，需要有
                    ElMessage.warning("请完善票据信息")
                    return
                }
                if(invoiceType=="SP"||invoiceType=="SH_SP"){
                    if(!taxAmount||taxAmount==0||!taxExcluded||taxExcluded==0||!inputTaxAmount||inputTaxAmount==0){ //专票或共用专票 税额，进项税额，不含税金额要有
                        ElMessage.warning("请完善票据信息")
                        return
                    }
                }
            }
             if(invoiceType=='NO'&&(!totalAmount||totalAmount==0)){ // 类型是无票时也判定金额是否为0或者没有
                ElMessage.warning("请完善票据信息")
                return
            }
            if(invoiceType=='BILL'&&!note){ //发票类型为单据时必须要填写备注
                ElMessage.warning("请填写备注信息")
                return
            }    
            data.canClick=true
            let res={id,invoiceType,invoiceCode,invoiceNo,invoiceDate:invoiceDate?dayjs(invoiceDate).format('YYYY-MM-DD'):'',totalAmount,note,taxRate,taxAmount,taxExcluded,inputTaxAmount,billId:currentCoId.value,isManual}             
            let params={details: isManual==1?res:'',...res}
            saveInvoiceApi(params as any).then((res:any)=>{
                data.id=res.id
                data.isEdit=true
                data.canClick=false
                ElMessage.success("保存成功")
            }).catch(()=>{
                data.canClick=false
            })
        }
        // 添加共享票据
        const saveSpInvoice = async (data:any)=>{

            
            let {invoiceType,invoiceCode,invoiceNo,invoiceDate,totalAmount,id,note, taxRate, taxAmount, taxExcluded }=data
            if(!invoiceType||!invoiceCode||!invoiceNo||!invoiceDate||!totalAmount || !taxRate || !taxAmount || !taxExcluded){ //判断票据类型是否
                ElMessage.warning("请完善票据信息")
                return
            }
            data.canClick=true
            let params = { id,invoiceType,
                invoiceCode,invoiceNo,
                invoiceDate:dayjs(invoiceDate).format('YYYY-MM-DD'),
                totalAmount,
                note, taxRate, taxAmount, taxExcluded
            }             
            await saveSpInvoiceApi(params).then((res:any)=>{
                data.id=res.id
                data.isEdit=true
                data.canClick=false
                ElMessage.success("保存成功")
            }).catch(()=>{
                data.canClick=false
            })
        }
        const totalAmountChange=(data:any)=>{
            // 总金额：totalAmount；税率：taxRate
            let {totalAmount, taxRate, isManual, invoiceType}=data
            // 税率非空且税率不为0
            if(!taxRate || taxRate == 0) return;
            // 总金额 -（总金额 / （税率 + 100%））
            let res = Number(totalAmount)-Number(totalAmount)/(1+Number(taxRate)/100)
            // 税额四舍五入
            data.taxAmount = Number(res.toFixed(2))
            // 不含税金额
            if(isManual == 0 || isManual === undefined || ['EI_OR', 'EI_SP'].includes(invoiceType)){
                data.taxExcluded = Number(Number(totalAmount - data.taxAmount).toFixed(2))
            }
            if(data.invoiceType && ['SP', 'SH_SP', 'EI_SP'].includes(data.invoiceType)){
                data.inputTaxAmount = data.taxAmount
            }
        }
        const invoiceTypeChange=(data:any)=>{
            let {invoiceType}=data
            // console.log(invoiceType)
            if(invoiceType!="SP"&&invoiceType!="SH_SP"){
                data.inputTaxAmount=0
            }else{
                data.inputTaxAmount=data.taxAmount
            }
        }
        const deleteInvoice=(data:any)=>{
            if(!data.row.id){
                invoiceData.value.splice(data.$index,1)
                return
            }
            ElMessageBox.confirm("确认删除已保存票据?",{
                
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            }).then(()=>{
                let formdata=new FormData()
                formdata.append("id",data.row.id)
                deleteInvoiceApi(formdata as any).then(()=>{
                    ElMessage.success("删除成功")
                    invoiceData.value.splice(data.$index,1)
                })
            })
        }
        const cancelInvalidInvoice=(data:any)=>{
            SP_IncoiceData.value.splice(data.$index,1)
        }
        const addSpInvoiceList=()=>{
            SpInvpiceVisible.value=true
           invoiceShareConditionsApi().then((res:any)=>{
                res.map((el:any)=>el.isEdit=true)
                SP_IncoiceData.value=res
            })
        }
        const invoiceType=(type:string)=>{
            switch(type){
                case "OR" :
                    return "普票"
                case "SP" :
                    return "专票"
                case "SH_OR" :
                    return "共用普票"
                case "SH_SP" :
                    return "共用专票"
                case "NO" :
                    return "无票"
            }
        }
        // 扫描枪票据号码识别
        const invoiceNoEnterMethod = (invoiceInfo: string) => {
            // 切割单个发票的号码(注意扫码枪输入法中文状态)
            let invoiceInfoArr = invoiceInfo.includes(',') ? invoiceInfo.split(",") : invoiceInfo.split("，")
            // 根据发票号码扫描结果,判断是否已存在
            let isInclude = invoiceData.value.find((_invNo: any) => _invNo.invoiceNo == invoiceInfoArr[3])
            if(isInclude){
                ElMessage.warning("票据已存在，不能重复添加")
                return
            }
            //解析出来的发票数组长度应该为8
            if(!invoiceInfoArr.length || invoiceInfoArr.length < 7) {
                ElMessage.warning("扫描异常，请重新扫描")
                return;
            }
            let invRow = {
                no: currentCoNo.value,
                isManual: 1,
                invoiceNo: invoiceInfoArr[3],
                invoiceCode: invoiceInfoArr[2],
                totalAmount: 0,
                taxExcluded: Number(invoiceInfoArr[4]),
                invoiceDate: dayjs(invoiceInfoArr[5]).format("YYYY-MM-DD"),
                invoiceType: 'SP',
            }
            // let _invoiceType = 'SP'
            if(['10', '04'].includes(invoiceInfoArr[1])){
                // _invoiceType = 'OR'
                invRow.invoiceType = 'OR'
                // 金额为不含税金额
                // invRow.taxExcluded = Number(invoiceInfoArr[4])
            }
            // 31,32金额为含税金额
            if(invoiceInfoArr[1] === '31'){
                // _invoiceType = 'EI_SP'
                invRow.invoiceType = 'EI_SP'
                invRow.totalAmount = Number(invoiceInfoArr[4])
                invRow.taxExcluded = 0
            }
            if(invoiceInfoArr[1] === '32'){
                // _invoiceType = 'EI_OR'
                invRow.invoiceType = 'EI_OR'
                invRow.totalAmount = Number(invoiceInfoArr[4])
                invRow.taxExcluded = 0
            }
            invoiceData.value.push(invRow)
            /* values.no = currentCoNo.value
            values.isManual = 1 //表示扫描添加(不可选择类型)
            values.invoiceNo = invoiceInfoArr[3]
            values.invoiceCode = invoiceInfoArr[2]
            values.taxExcluded = Number(invoiceInfoArr[4])
            values.invoiceDate = dayjs(invoiceInfoArr[5]).format("YYYY-MM-DD")
            invoiceData.value.push(values) */
            // 添加发票内容
            // 发票类型为:'EI_SP', 'EI_OR',数组第四元素为总金额(价税合计)
            /* invoiceData.value.push({
                no: currentCoNo.value,
                isManual: 1,
                invoiceNo: invoiceInfoArr[3],
                invoiceCode: invoiceInfoArr[2],
                taxExcluded: Number(invoiceInfoArr[4]),
                invoiceDate: dayjs(invoiceInfoArr[5]).format("YYYY-MM-DD"),
                invoiceType: _invoiceType,
                totalAmount: 0
            }) */
        }
        const backMethos=(value:any)=>{
            backReason.reason=""
            backReason.reimburseId=value.id
            backReason.payApproveStatus=value.approvedStatus
            backDialogVisiable.value=true

        } 
        const saveBackReason=()=>{
            if(!backReason.reason){
                ElMessage.warning("请输入回退原因")
                return
            }
            let formdata=new FormData()
            formdata.append("reason",backReason.reason)
            formdata.append("billId",backReason.reimburseId)
            formdata.append("payApproveStatus",backReason.payApproveStatus)
            approvedRollBackApi(formdata as any).then(()=>{
                
                ElMessage.success("操作成功")
                backDialogVisiable.value=false
                getTableData()
            })
        }
        const invoiceTotalAmount=ref(0)
         const getInvoiceTotalAmount=(value:number)=>{
            invoiceTotalAmount.value=value
        }
        const closeInvoiceDialog=(isConfirm:boolean)=>{
            if(!isConfirm){
                taxDialogVisible.value=false
                getTableData()
                return
            }
            if(Sub(invoiceTotalAmount.value,currentCoEstimateAmount.value)>0){
                ElMessageBox.confirm("请确认是否还有发票需要添加，或添加无票行，当前余额不为0，是否关闭？",{
                
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:"warning"
                
                }).then(()=>{
                    taxDialogVisible.value=false
                    getTableData()
                })
            }else{
                taxDialogVisible.value=false
                getTableData()
            }
            
        }
        const locakData=(data: any,value: number)=>{
            let title = value === 1 ? "请确认是否锁定？" : "请确认是否解锁？"
            ElMessageBox.confirm(title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                lockedApi({ id: data.id, locked: value }).then((res:any)=>{
                    ElMessage.success('修改成功')
                    getTableData()
                })
            })
            
        }
        const backPre=()=>{
            router.back()
        }
        // 删除共享票据
        const delInvBtnFg = ref(false)
        const delInvalidInvoice = (row: any) => {
            ElMessageBox.confirm(
                '是否确认删除?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    delInvBtnFg.value = true
                    const formdata = new FormData()
                    formdata.append('id', row.id)
                    return delInvoiceApi(formdata as any)
                })
                .then(() => {
                    delInvBtnFg.value = false
                    ElMessage({ type: 'success', message: '删除成功', })
                    addSpInvoiceList()
                })
                .catch(() => {
                    delInvBtnFg.value = false
                })
            
        }
        return{
            historyDetailData,
            tableLoading,
            pageChange,
            pageSetting,
            editTax,
            taxDialogVisible,
            taxFormInline,
            changeTax,
            approvalDialogVisiable,
            barCode,
            approvalDataLoading,
            approvalList,
            approvalData,
            approvalProcess,
            saveApproval,
            expenseType,
            filterMethod,
            invoiceData,
            addInvoice,
            saveInvoice,
            totalAmountChange,
            dayjs,
            invoiceTypeChange,
            deleteInvoice,
            invoiceType,
            addSpInvoice,
            SpInvpiceVisible,
            SP_IncoiceData,
            saveSpInvoice,
            cancelInvalidInvoice,
            invoiceNoEnterMethod,
            addSpInvoiceList,
            currentCoEstimateAmount,
            backMethos,
            backDialogVisiable,
            backReason,
            saveBackReason,
            getInvoiceTotalAmount,
            closeInvoiceDialog,
            invoiceLoading,
            locakData,
            backPre,
            delInvalidInvoice,
            delInvBtnFg,
        }
       
    }
})
